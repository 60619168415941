import { useEffect } from 'react';
import classNames from 'classnames';
import { iframely } from '@iframely/embed.js';

export default function Post({ altType, post, pinned }) {
  const utcPublishAt = new Date(post.publish_at);

  let media = post.media;

  if (altType) {
    const alt = (post.alt || []).find(a => a.identifier === altType.identifier);

    if (alt) {
      media = alt.media;
    }
  }

  useEffect(() => {
    iframely.load();
  }, [ media ]);

  // not at all reacty, but does the job in a pinch
  const reload = e => {
    const { target } = e;

    const closest = target.closest('.post');
    const iframe = closest.querySelector('iframe');

    iframe.src += '';
  };

  return (
    <div
      className={classNames("post mb-4", {
        'post--pinned': pinned,
        ['post--' + post.style]: !!post.style,
      })}
    >
      <h2>{ post.title }</h2>

      { post.body && (
        post.body.split(/\n+/g).map((p, i) => (
          <p key={i}>{p}</p>
        ))
      ) }

      <div
        className="post__media card"
        dangerouslySetInnerHTML={{
          __html: media.html,
        }}
      />

      <div className="post__meta text-end text-muted">
        <small>
          { (media.html.includes("youtube")) && (
            <>
              <a onClick={reload} href="javascript:;" title="Click here to reload">Can't see the video?</a> &bull;{' '}
            </>
          ) }

          { (post.media !== media) && (
            <>
              <i className={classNames('fas', altType.icon)} /><span className="visually-hidden">{ altType.name }</span> &bull;{' '}
            </>
          ) }

          { utcPublishAt.toLocaleString('en-GB', {
            dateStyle: 'medium',
            timeStyle: 'short',
          }) }
        </small>
      </div>
    </div>
  );
}
