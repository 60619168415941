import querystring from 'querystring';
import { Component } from 'react';
import classNames from 'classnames';

import AntSwitch from './components/AntSwitch';
import Post from './components/Post';

// eslint-disable-next-line no-restricted-globals
const h = location.hostname;
let BASE_URL;

if (h.match(/localhost/)) {
  BASE_URL = 'http://192.168.1.10:8787';
}

if (h.match(/coventry2021/)) {
  BASE_URL = 'https://worker.coventry2021.online';
}

if (h.match(/preview/)) {
  BASE_URL = 'https://preview-worker.coventry2021.online';
}

const ARCHIVE_MODE = true;

const ALT_TYPES = [{
  identifier: 1,
  name: "BSL Interpreting",
  icon: "fa-sign-language",
}, {
  identifier: 2,
  name: "Subtitles",
  icon: "fa-closed-captioning",
}, {
  identifier: 3,
  name: "Audio Description",
  icon: "fa-audio-description",
}];

const SOCIAL_LINKS = [{
  name: 'Facebook',
  url: 'https://www.facebook.com/coventry2021/',
  color: 'rgb(59, 89, 152)',
  icon: 'fa-facebook',
}, {
  name: 'Twitter',
  url: 'https://twitter.com/Coventry2021',
  color: 'rgb(85, 172, 238)',
  icon: 'fa-twitter',
}, {
  name: 'Instagram',
  url: 'https://www.instagram.com/coventry2021/',
  color: 'linear-gradient(45deg, rgb(248, 212, 58) 0%, rgb(240, 74, 110) 50%, rgb(150, 42, 171) 100%)',
  icon: 'fa-instagram',
}, {
  name: 'YouTube',
  url: 'https://www.youtube.com/channel/UCIpBngO0nn6_2NHH7HAjreg',
  color: 'rgb(207, 34, 0)',
  icon: 'fa-youtube',
}, {
  name: 'BBC CWR',
  url: 'https://bbc.co.uk/cwr',
  color: 'rgb(86, 36, 131)',
  image: require('./assets/bbc-cwr.jpg').default,
}];

const SPONSORS = [{
  url: "https://www.coventry.gov.uk/",
  name: "Coventry City Council",
  image: require('./assets/sponsors/coventry-cc.jpg').default,
}, {
  url: "https://www.wmca.org.uk/",
  name: "West Midlands Combined Authority",
  image: require('./assets/sponsors/wmca.jpg').default,
}, {
  name: "DCMS",
  image: require('./assets/sponsors/dcms.jpg').default,
}, {
  name: "",
  image: require('./assets/sponsors/lottery-arts.jpg').default,
}, {
  url: "https://coventry2021.co.uk/about-our-partners/principal-partner-national-lottery-heritage-fund/",
  name: "HLF",
  image: require('./assets/sponsors/lottery-heritage.png').default,
}, {
  url: "https://coventry2021.co.uk/about/information-about-our-partners/regional-partner-national-lottery-community-fund/",
  name: "National Lottery Community Fund",
  image: require('./assets/sponsors/lottery-community.jpg').default,
}, {
  url: "https://www.warwickshire.gov.uk/",
  name: "Warwickshire County Council",
  image: require('./assets/sponsors/warwickshire-cc.jpg').default,
}, {
  url: "https://coventry2021.co.uk/about-our-partners/principal-partner-spirit-of-2012/",
  name: "Spirit Of 2012",
  image: require('./assets/sponsors/spirit.jpg').default,
}, {
  url: "https://www.coventry.ac.uk/",
  name: "Cov Uni",
  image: require('./assets/sponsors/cov-uni.jpg').default,
}, {
  url: "https://warwick.ac.uk/",
  name: "University of Warwick",
  image: require('./assets/sponsors/warwick-uni.jpg').default,
}];

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingPoll: false,
      loadingMore: false,
      appends: 0,
      serverTimeOffset: 0,
      pinnedPost: null,
      posts: [],
      altType: null,

      canLoadMore: true,
      error: false,
    };
  }

  poll = () => {
    const posts = this.state.posts;
    const qs = {};

    if (ARCHIVE_MODE) {
      qs.order = 'asc';
    }

    if (posts.length > 0) {
      qs[ARCHIVE_MODE ? 'before' : 'after'] = posts.reduce((i, p) => {
        if (ARCHIVE_MODE && p.publish_at < i) {
          return p.publish_at;
        }

        if (!ARCHIVE_MODE && p.publish_at > i) {
          return p.publish_at;
        }

        return i;
      }, (ARCHIVE_MODE) ? Infinity : 0);
    }

    this.setState({
      loadingPoll: true,
    });

    const clientStartTime = Date.now();

    fetch(`${BASE_URL}/poll?${querystring.stringify(qs)}`).then(res => {
      const serverTime = new Date(res.headers.get('date')).getTime();
      const clientEndTime = Date.now();

      const serverClientRequestDiffTime = serverTime - clientStartTime;
      const serverClientResponseDiffTime = clientEndTime - serverTime;

      const serverTimeOffset = (Math.abs(serverClientRequestDiffTime) + Math.abs(serverClientResponseDiffTime)) / 2;

      return res.json().then(json => {
        const { pinned, posts: newPosts } = json;

        const now = Date.now();
        const freq = 1000 * 60;
        const grace = 1000 * 3;
        const t = (freq - (now - (Math.floor(now / freq) * freq)) + serverTimeOffset + grace) % freq;

        this.setState({
          error: false,
          loadingPoll: false,
          serverTimeOffset: serverTimeOffset,
          pinnedPost: pinned,
          posts: [
            ...newPosts,
            ...posts,
          ],
        });

        this.timeout = setTimeout(this.poll.bind(this), Math.max(t, grace));
      });
    }).catch(e => {
      this.setState({
        error: true,
        loadingPoll: false,
      });

      this.timeout = setTimeout(this.poll.bind(this), 60 * 1000);
    });
  }

  handleLoadMore = force => {
    const { canLoadMore, error, loadingMore, posts } = this.state;
    const qs = {};

    if (!force && (error || loadingMore || !canLoadMore)) {
      return;
    }

    if (ARCHIVE_MODE) {
      qs.order = 'asc';
    }

    if (posts.length > 0) {
      qs[ARCHIVE_MODE ? 'after' : 'before'] = posts.reduce((i, p) => {
        if (ARCHIVE_MODE && p.publish_at > i) {
          return p.publish_at;
        }

        if (!ARCHIVE_MODE && p.publish_at < i) {
          return p.publish_at;
        }

        return i;
      }, (ARCHIVE_MODE) ? 0 : Infinity);
    }

    this.setState({
      loadingMore: true,
    });

    fetch(`${BASE_URL}/poll?${querystring.stringify(qs)}`).then(res => {
      return res.json();
    }).then(json => {
      const { pinned, posts: newPosts } = json;

      this.setState({
        canLoadMore: newPosts.length > 0,

        error: false,
        appends: this.state.appends + 1,
        loadingMore: false,
        pinnedPost: pinned,
        posts: [
          ...posts,
          ...newPosts,
        ],
      });
    }).catch(e => {
      this.setState({
        error: true,
        loadingMore: false,
      });
    });
  }

  setBottomBoundaryRef = el => {
    if (this.observer) {
      this.observer.unobserve(this.bottomBoundaryRef);
      this.observer = null;
    }

    this.bottomBoundaryRef = el;

    if (el) {
      this.observer = new IntersectionObserver(entries => {
        entries.forEach(en => {
          if (this.state.appends < 5 && en.isIntersecting) {
            this.handleLoadMore();
          }
        });
      });

      this.observer.observe(el);
    }
  }

  componentDidMount() {
    this.timeout = setTimeout(this.poll.bind(this), 10 * 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    const { altType, canLoadMore, loadingMore, pinnedPost, posts } = this.state;

    return (
      <>
        <header style={{ backgroundColor: '#1c1c1c' }}>
          <a href="https://coventry2021.co.uk">
            <img className="d-none d-sm-inline-block" src={ require('./assets/ccoc_2021_logo_desktop.svg').default } alt="Coventry City of Culture" style={{ width: '28rem' }} />
            <img className="d-sm-none" src={ require('./assets/ccoc_2021_logo_mobile.svg').default } alt="Coventry City of Culture" style={{ paddingLeft: '1rem', width: '14rem' }} />
          </a>
        </header>

        <section className="app">
          <div className="container mt-4">
            <div className="row">
              <div className="col-12 col-lg-4 col-md-5">
                <div className="card card-body mb-4">
                  <img src={ require('./assets/moves-01.jpg').default } className="img-fluid" alt="Map of Coventry with icons" />

                  <h1>Coventry Moves</h1>
                  <p>Coventry Moves will be a playful and engaging introduction to Coventry, its stories, and its people, and will be an opportunity for every home in Coventry to be part of our year as UK City of Culture. This page will be updated live on 5 June with live streams, behind-the-scenes content and short films and is the best place to experience Coventry Moves.</p>

                  <div className="row">
                    <div className="col-6 col-md-12">
                      <ul className="social-links mt-2 mb-4">
                        { SOCIAL_LINKS.map((s, i) => (
                          <li key={i} className="social-links__item">
                            <a href={s.url} style={{ background: s.color }} className="social-links__link" target="_blank" rel="noreferrer">
                              { (s.icon) && <>
                                <i className={classNames('fab', s.icon)} />
                                <span className="visually-hidden">{ s.name }</span>
                              </> }

                              { (s.image) && <img src={s.image} alt={s.name} /> }
                            </a>
                          </li>
                        )) }
                      </ul>
                    </div>

                    <div className="col-6 col-md-12">
                      <ul className="alt-list">
                        { ALT_TYPES.map(a => (
                          <li className="alt-list__item" key={a.identifier}>
                            <AntSwitch id={"alt_" + a.identifier} checked={altType && altType.identifier === a.identifier} onChange={(e, checked) => { this.setState({ altType: (checked) ? a : null }) }} />
                            <label htmlFor={"alt_" + a.identifier}>{ a.name } for Videos</label>
                          </li>
                        )) }
                      </ul>
                    </div>

                    <div className="col-12">
                      <a href="https://coventry2021.co.uk/about/coventry-moves-credits/" target="_blank" rel="noreferrer">Coventry Moves Credits</a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-8 col-md-7">
                <div className="posts">
                  { pinnedPost && (
                    <Post altType={altType} post={pinnedPost} pinned={true} />
                  ) }

                  { posts.map(p => (
                    <Post key={p.id} altType={altType} post={p} />
                  )) }
                </div>

                { (loadingMore > 0) && (
                  <div className="border text-center p-4 mb-4">
                    Loading...
                  </div>
                ) }

                { (canLoadMore && !loadingMore) && (
                  <div ref={this.setBottomBoundaryRef}>
                    <button className="btn btn-outline-secondary w-100 p-2 mb-4" onClick={this.handleLoadMore}>
                      { (posts.length === 0) ? 'Try again...' : 'Load more...' }
                    </button>
                  </div>
                ) }

                { (!canLoadMore) && (
                  <div className="text-center text-muted p-4 mb-4">
                    <small>That's everything!</small>
                  </div>
                ) }
              </div>
            </div>
          </div>
        </section>

        <footer>
          <section className="partners">
            <div className="partners__title">
              Our principal partners
            </div>

            <div className="sponsors">
              {SPONSORS.map((s, i) => (
                <div key={i} className="sponsors__item">
                  <a href={s.url || '#'}>
                    <img src={s.image} alt={s.name} />
                  </a>
                </div>
              ))}
            </div>
          </section>

          <section className="py-3 border-top text-center">
            &copy; 2021 Coventry UK City of Culture 2021 | Coventry2021.online Experience by <a href="https://orch.is/?utm_source=coventry2021.online&utm_medium=attrib-footer" target="_blank" rel="noreferrer"><img src="https://assets.orch.is/logo/orchis_in.svg" style={{ padding: '0 0 3px 2px' }} height="16" alt="Orchis" /></a>
          </section>
        </footer>
      </>
    );
  }
}
